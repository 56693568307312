'use client'

import {
  composeRenderProps,
  Button as RACButton,
  type ButtonProps as RACButtonProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { cn } from '../lib/cn'
import { focusRing } from '../lib/utils'

export interface ButtonProps extends RACButtonProps {
  variant?:
    | 'primary'
    | 'primary-p2'
    | 'secondary'
    | 'third'
    | 'text'
    | 'gray'
    | 'base'
    | 'glass-options'
    | 'label-l1'
    | 'label-l2'
  buttonRef?: React.Ref<HTMLButtonElement>
  loading?: boolean
  loadingClassName?: string
  loadingIcon?: React.ReactNode
  children: React.ReactNode
}

export const buttonVariant = tv({
  extend: focusRing,
  base: 'flex cursor-pointer flex-nowrap items-center justify-center rounded-[12px] border border-transparent transition duration-300 disabled:cursor-not-allowed disabled:opacity-50',
  variants: {
    variant: {
      primary:
        'bg-primary-p1 text-primary-on-primary pressed:bg-primary-p1 hover:bg-primary-p1 font-semibold',
      'primary-p2':
        'bg-primary-p2 text-primary-p1 hover:bg-primary-p2-hover font-semibold',
      secondary:
        'bg-secondary-p2 pressed:bg-secondary-p2 hover:bg-secondary-p2 text-primary-p1 font-semibold',
      third: 'bg-third-p1 text-third-on-third',
      text: 'text-primary-p1 bg-transparent font-semibold',
      gray: 'bg-background-2nd text-label-l2',
      base: 'border-label-l3 bg-background-1st hover:bg-background-1st border border-solid',
      'glass-options': 'bg-glass-special-options text-playseeGray-gray1',
      'label-l1': 'text-label-l1 bg-transparent',
      'label-l2': 'text-label-l2 bg-transparent font-semibold',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export function Button({
  children,
  loadingClassName,
  loading,
  loadingIcon,
  ...props
}: ButtonProps) {
  return (
    <RACButton
      {...props}
      ref={props.buttonRef}
      className={composeRenderProps(props.className, (className, renderProps) =>
        buttonVariant({ ...renderProps, variant: props.variant, className }),
      )}
    >
      {children}
      {loading && loadingIcon}
      {loading && !loadingIcon && (
        <span
          className={cn(
            'i-ps-cell_loading ml-1 animate-spin text-[#FAFDFF]',
            loadingClassName,
          )}
        />
      )}
    </RACButton>
  )
}
