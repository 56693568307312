import { composeRenderProps } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

export const focusRing = tv({
  base: 'outline-primary-p1 outline outline-offset-2 forced-colors:outline-[Highlight]',
  variants: {
    isFocusVisible: {
      false: 'outline-0',
      true: 'outline-2',
    },
  },
})

export const bubbleAnimation = tv({
  base: 'animate-in zoom-in-50 ease-in-bubble [animation-duration:var(--duration,0)]',
  variants: {
    from: {
      left: 'origin-bottom-left',
      right: 'origin-bottom-right',
    },
  },
})

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string,
): string | ((v: T) => string) {
  return composeRenderProps(className, className => twMerge(tw, className))
}
