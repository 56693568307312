'use client'

import { Button } from '@paladise/ui/components/Button'
import { cn } from '@paladise/ui/lib/cn'
import { Link } from 'lib/navigation'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    container: 'px-4',
    link: 'flex h-[36px] w-full items-center text-lg font-semibold',
  },
})

const { container, link } = styles()

const HamburgerMenu = ({
  buttonClassName,
  popupClassName,
}: {
  buttonClassName?: string
  popupClassName?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const t = useTranslations()

  return (
    <nav role="navigation" aria-label="Main menu">
      <Button
        variant="label-l1"
        className={buttonClassName}
        aria-label="Menu"
        aria-controls="hamburger-menu"
        aria-expanded={isOpen}
        onPress={() => setIsOpen(true)}
      >
        <span
          style={{ '--color': 'var(--button-color, var(--color-label-l1))' }}
          className="i-ps-menu text-[--color] [--icon-size:24px]"
          aria-hidden="true"
        />
      </Button>

      <div
        id="hamburger-menu"
        className={cn(
          'z-popover fixed left-0 top-0 h-dvh w-full bg-white transition-transform duration-300',
          isOpen ? 'translate-x-0' : '-translate-x-full',
          popupClassName,
        )}
        aria-hidden={!isOpen}
      >
        {/* Header */}
        <div className="h-mobile-fixed-top sticky top-0 z-10 flex items-center bg-white px-4 py-3">
          <Button variant="label-l1" onPress={() => setIsOpen(false)}>
            <span className="i-ps-close [--icon-size:24px]" />
          </Button>
          <img
            src="/images/logo.svg"
            alt="PalUp"
            className="min768:!h-[33px] min768:!w-[80px] absolute left-1/2 top-1/2 !h-[30px] !w-[72px] -translate-x-1/2 -translate-y-1/2"
            role="img"
            aria-label="Logo"
            onClick={() => setIsOpen(false)}
          />
        </div>

        <ul className="mt-5 flex flex-col gap-3">
          <li className={container()}>
            {/* About */}
            <Link
              href="/about"
              className={link()}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <span>{t('about')}</span>
              <span className="i-ps-chevron_right ml-auto block [--icon-size:18px]"></span>
            </Link>
          </li>
          {/* Contact */}
          <li className={container()}>
            <Link
              href="/contact"
              className={link()}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <span>{t('common.Contact')}</span>
              <span className="i-ps-chevron_right ml-auto block [--icon-size:18px]"></span>
            </Link>
          </li>
          {/* Help */}
          <li className={container()}>
            <Link
              href="/help"
              className={link()}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <span>{t('help_center')}</span>
              <span className="i-ps-chevron_right ml-auto block [--icon-size:18px]"></span>
            </Link>
          </li>
          {/* Terms */}
          <li className={container()}>
            <Link
              href="/user-terms-of-service"
              className={link()}
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <span>{t('user_terms_of_service')}</span>
              <span className="i-ps-chevron_right ml-auto block [--icon-size:18px]"></span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default HamburgerMenu
